// Контейнер авторизационного компонента типа signIn (работает и для попапа авторизации, и для страницы sessions/new)
app.modules.signInAuthComponentContainer = (function (self) {
  self.getOptions = function () {
    return {
      getAdditionalUserData: function () {
        const additionalData = {
            profile_attributes: {city_id: app.config.currentRegionCapitalId},
          },
          phoneInput = document.querySelector(
            '.js-sign-in-popup .js-input-phone-auth'
          );
        phoneInput &&
          phoneInput.value &&
          (additionalData.profile_attributes.contacts = phoneInput.value);
        return additionalData;
      },
    };
  };

  return self;
})(app.modules.signInAuthComponentContainer || {});
