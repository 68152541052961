// Инициализация авторизационного компонента для форм подписки на акции

app.modules.dealsSubscriptionInPanelAuthComponentContainer = (function (self) {
  self = function (selector) {
    $(selector).trigger('render:authComponent', [
      {
        simpleRegistration: true,
        orientation: 'vertical',
        contentType: 'common',
        withoutSpinner: true,
        content: {
          common: {
            tip: null,
            title: null,
          },
        },
        containerName: 'dealsSubscriptionInPanel',
        fields: {
          set: ['email'],
          hiddenByDefault: ['password'],
        },
        onSearch: app.modules.dealsSubscriptionFormInPanel.onUserSearch,
        onError: app.modules.dealsSubscriptionFormInPanel.onSubscribe,
        onCompleted: function () {
          app.modules.dealsSubscriptionFormInPanel.onSubscribe();
        },
        getAdditionalUserData: function () {
          const userData = {
            profile_attributes: {city_id: app.config.currentRegionCapitalId},
          };

          if (app.config.registrationFromPanelTarget) {
            userData['purpose_ids'] = app.config.registrationFromPanelTarget;
          }

          return userData;
        },
      },
    ]);
  };

  return self;
})(app.modules.dealsSubscriptionInPanelAuthComponentContainer || {});
