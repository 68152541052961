// Контейнер авторизационного компонента типа demands
app.modules.demandsAuthComponentContainer = (function (self) {
  self.getOptions = function () {
    return {
      onError: function () {
        app.modules.demandsProject.unlockForm($('.js-demands-form')[0]);
      },
      onCompleted: function () {
        app.modules.demandsProject.sendDemand();
      },
      getAdditionalUserData: function () {
        const additionalData = {
            profile_attributes: {city_id: app.config.currentRegionCapitalId},
            delivery_options: {event_from: 'demands'},
          },
          phoneInput = document.querySelector(
            '.js-demands-form .js-input-phone-auth'
          );
        phoneInput &&
          phoneInput.value &&
          (additionalData.profile_attributes.contacts = phoneInput.value);
        return additionalData;
      },
    };
  };

  return self;
})(app.modules.demandsAuthComponentContainer || {});
