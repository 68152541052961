// Контейнер авторизационного компонента типа dealsSubscriptionInPopup

// Контейнеры авторизационного компонента предназначены для передачи компоненту
// динамически расчитываемых свойств, коллбэков.

app.modules.dealsSubscriptionInPopupAuthComponentContainer = (function (self) {
  self.getOptions = function () {
    return {
      onError: app.modules.subscriptionsPopup.onSubscribe,
      onCompleted: function () {
        app.modules.subscriptionsPopup.onSubscribe();
      },
    };
  };

  return self;
})(app.modules.dealsSubscriptionInPopupAuthComponentContainer || {});
