// Контейнер авторизационного компонента типа reviews
app.modules.reviewsAuthComponentContainer = (function (self) {
  self.getOptions = function () {
    return {
      getAdditionalUserData: function () {
        return {
          profile_attributes: {city_id: app.config.currentRegionCapitalId},
        };
      },
    };
  };

  return self;
})(app.modules.reviewsAuthComponentContainer || {});
